import React from 'react';
import Header from '../components/Header';

import Footer from '../components/Footer';
import Layout from '../components/Layout';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout>
    <section>
        <div className="container">
          <Header />
          <h2>
            FAQ
          </h2>

            <br />

            <h4 className="locationError" id="locationError">Receiving `Unable to get location, you must enable location permission` error</h4>
            <p>
              If you receive this error there can be a few reasons:
              <ol>
                <li>You have denied permission for the app to access your location</li>
                  <ul>
                    <li>Please check the permissions for the app and ensure that you have location permission enabled</li>
                    <li>
                      On IOS devices (iPhone, iPad etc.) go to Setting > nearo > Location. We recommend you select 'While Using the App' as we only ever
                      need your location when using the app. It is also recommened that you provide precide location. Nearo will only ever get an approximate location.
                    </li>
                  </ul>
                <li> Location servives are disabled on your device</li>
                  <ul><li>Please ensure that location services are enabled on your device</li></ul>
                <li>The app was unable to get your location after a timeout</li>
                <ul>
                <li>You should try load the app again</li>
                <li>If you have an Android device the issue may be with battery saving settings on your device. You can try the steps bellow:</li>
                <ul>
                  <li>HTC: Access your phone settings > battery > power saving mode > battery optimization > select your app > don't optimize > save</li>
                  <li>Huawei: Turn Energy Settings to Normal and add your app to "Protected Apps"</li>
                  <li>LG If you're running Android 6 or higher: Settings > battery & power saving > battery usage > ignore optimizations > turn ON for your app</li>
                  <li>Motorola If you're running Android 6 or higher: Battery > select the menu in the upper right-hand corner > battery optimization > not optimized > all apps > select your app > don't optimize</li>
                  <li>OnePlus (using OxygenOS Settings): Battery > battery optimization > switch to 'all apps' > select your app > don't optimize</li>
                  <li>Samsung: Access battery settings > app power saving > details > your app > disabled</li>
                  <li>Sony If you're running Android 6 or higher: Battery > from the menu in the upper right-hand corner > battery optimization > apps > your app</li>
                  <li>Xiaomi (MIUI OS) If you're running Android 6 or higher: Access your phone settings > additional settings > battery and performance > manage battery usage > apps > your app</li>
                </ul>
                </ul>
              </ol>
            </p>

            <h4>Unable to sign in</h4>
            <p>
              If you are unable to sign in, first make sure that you are signing in with the correct authentication provider and that your credentials are correct.
              If you still continue to have issue please contact us at <a href="mailto:info@nearo.co">info@nearo.co</a>.
            </p>

            <h4>Other Issue</h4>
            <p>
              Having a problem that isn't answered here? You can get in contact with us at <a href="mailto:info@nearo.co">info@nearo.co</a>. If you have found a bug,
              please report it on our <a href="https://github.com/nearoapp/nearo-bugs">GitHub</a> 
            </p>


        </div>
      <div className="overlay"></div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
